//this is my Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, mostly interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions{all PATENTED}.

import React, { useState, useRef, useEffect } from "react";
import PlayerDetails from "./PlayerDetails";
import PlayerControls from "./PlayerControls";
import equalizerBgImage from "../../../images/Music_Equalizer_Grid.png"; 
// import SpectrumAnalyzer3D from "./SpectrumAnalyzer3D_work_in_progress"; 
import 'atropos/css';
import SpectrumAnalyzer from './SpectrumAnalyzer';
import AtroposImage from "./AtroposImage.js";

function sdflhsfkh() {
  const sfsfssfsdfs = navigator.userAgent.toLowerCase();
  const dfjksdfkj = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return dfjksdfkj.some((keyword) => sfsfssfsdfs.includes(keyword));
}

function Sdvjsdfjkasdhjhjh(props) {
  const ksjhkgakETUUIUI = useRef(null);
  const [mcnVmcn, yuyhgdhgfh] = useState(false);
  const [likes, setLikes] = useState(props.songs.map(() => 100));
  const [tdusfsfsdfTud] = useState(true);
  
  const nbzxcnzbSfh = useRef(null);
  const nbzxcnzbSfh2 = useRef(null);
  const mmnnSfh = useRef(null);
  const nbxnTgi = useRef(null);
  const nbxnTgi2 = useRef(null);
  const zzmnmnzxmnzmxnzQde = useRef(new (window.AudioContext || window.webkitAudioContext)()); //audioContextRef
  const laslkallkllasQde = useRef(zzmnmnzxmnzmxnzQde.current.createAnalyser());
  const laslkallkllasTgi = useRef(null);
  const [oiuytyui, swdPnkeij] = useState(0);
  var isSkipSong = true;
  const [mnczbxncb, mnczbxncb2] = useState(null);
  const [xvczvc, xvczvc2] = useState(null);
  const [erqrttwe, erqrttwe2] = useState(null);
  const options = [
    { text: 'Red', value: 1, color: 'red', red: 255, green: 0, blue: 0 },
    { text: 'Green', value: 3, color: 'green', red: 124, green: 252, blue: 0 },
    { text: 'Blue', value: 2, color: 'blue', red: 0, green: 0, blue: 205 },
    { text: 'Yellow', value: 4, color: 'yellow', red: 255, green: 217, blue: 5   }
  ];
  const [selectedValue, setSelectedValue] = useState(null);
  const [rvrxrrxeeGwzxw, zxqzqUymym] = useState(false);
  const [rvrxrrxeeGwzxw2, zxqzqUymym2] = useState(false); 

  const qwerTsdfg = (option)  =>  { 
    setSelectedValue(option);
    mnczbxncb2(option.red);
    xvczvc2(option.green);
    erqrttwe2(option.blue);
    zxqzqUymym(!rvrxrrxeeGwzxw); 
    zxqzqUymym2(!rvrxrrxeeGwzxw2); 
  }

  useEffect(() => {
    const canvas = nbzxcnzbSfh.current;
if (canvas != null) {
    const mcnxmaqdasdaEvz = canvas.getContext('2d');   
    mcnxmaqdasdaEvz.fillStyle = 'lightblue';
    const mcnxmaqdasdatfs = laslkallkllasQde.current;
    
    if(sdflhsfkh) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight/4;
    } else {
        canvas.width = window.innerWidth*.25;
        canvas.height = window.innerHeight*3.75;
    }
    mcnxmaqdasdatfs.fftSize = 64;
    const wqpwoqalskalMfo = mcnxmaqdasdatfs.frequencyBinCount;
    let erzrzyc   = new Uint8Array(wqpwoqalskalMfo);
    mcnxmaqdasdaEvz.clearRect(0, 0, canvas.width, canvas.height);
    const qeuqeqweiqweiowiweioqu = () => {
      if (nbxnTgi) {
        const img = new Image();
        img.src = "{equalizerBgImage}";
        img.onload = () => { 
        const pattern = mcnxmaqdasdaEvz.createPattern(img, 'repeat');
        mcnxmaqdasdaEvz.fillStyle = pattern;
        mcnxmaqdasdaEvz.fillRect(10, 10, 100, 100); 
      };
      nbxnTgi.current = requestAnimationFrame(qeuqeqweiqweiowiweioqu);
      mcnxmaqdasdatfs.getByteFrequencyData(erzrzyc);
      mcnxmaqdasdaEvz.fillStyle = 'rgb(0, 0, 0)';
      mcnxmaqdasdaEvz.fillRect(0, 0, canvas.width, canvas.height);
      const zxmnzxmnk =  (canvas.width / wqpwoqalskalMfo) * 1.45;      
      let aslakslakasasa;
      let x = 0;

      for (let i = 0; i < wqpwoqalskalMfo; i++) {
        aslakslakasasa = erzrzyc[i];
        const iwyeqieyqwyeqouyuyweww = `rgb(${mnczbxncb }, ${xvczvc}, ${erqrttwe})`;
        mcnxmaqdasdaEvz.fillStyle = iwyeqieyqwyeqouyuyweww;
        mcnxmaqdasdaEvz.fillRect(x, canvas.height - aslakslakasasa / 2, zxmnzxmnk, aslakslakasasa / 2);
        x += zxmnzxmnk + 1;
      }      
    }
    };
    qeuqeqweiqweiowiweioqu();
    return () => {
      cancelAnimationFrame(nbxnTgi.current);
    };
  }
  }, [erqrttwe, xvczvc, mnczbxncb, rvrxrrxeeGwzxw, equalizerBgImage]);

  
  useEffect(() => {
    const canvas = nbzxcnzbSfh2.current;
    if(canvas != null) {
    const mcnxmaqdasdaEvz2 = canvas.getContext('2d');
    const mcnxmaqdasdatfs = laslkallkllasQde.current;
    
    if(sdflhsfkh) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight/4;
    } else {
        canvas.width = window.innerWidth*.25;
        canvas.height = window.innerHeight*3.75;
    }
    mcnxmaqdasdatfs.fftSize = 64;
    const wqpwoqalskalMf2 = mcnxmaqdasdatfs.frequencyBinCount;
    let erzrzyc2 = new Uint8Array(wqpwoqalskalMf2);
    mcnxmaqdasdaEvz2.clearRect(0, 0, canvas.width, canvas.height);
    const qeuqeqweiqweiowiweioqu2 = () => {
      if (nbxnTgi2) {
      nbxnTgi2.current = requestAnimationFrame(qeuqeqweiqweiowiweioqu2);
      mcnxmaqdasdatfs.getByteFrequencyData(erzrzyc2);
      mcnxmaqdasdaEvz2.fillStyle = 'rgb(0, 0, 0)';
      mcnxmaqdasdaEvz2.fillRect(0, 0, canvas.width, canvas.height);
      const zxmnzxmnk2 = (canvas.width / wqpwoqalskalMf2) * 1.45;
      let aslakslakasasa;
      let x = 0;
     
      for (let i = 0; i < wqpwoqalskalMf2; i++) {
        aslakslakasasa = erzrzyc2[i];  
        const iwyeqieyqwyeqouyuyweww2 = `rgb(${mnczbxncb }, ${xvczvc}, ${erqrttwe})`;
        mcnxmaqdasdaEvz2.fillStyle = iwyeqieyqwyeqouyuyweww2;
        mcnxmaqdasdaEvz2.fillRect(x, canvas.height - aslakslakasasa / 2, zxmnzxmnk2, aslakslakasasa / 2);
        x += zxmnzxmnk2 + 1;
      }
    }
    };
    qeuqeqweiqweiowiweioqu2();

    return () => {
      cancelAnimationFrame(nbxnTgi2.current);
    };
  }
  }, [erqrttwe, xvczvc, mnczbxncb, rvrxrrxeeGwzxw2]); 

  useEffect(() => {
    if (tdusfsfsdfTud != null && tdusfsfsdfTud.current != null) {
    mppqbcwewrxexWknjan(props.songs[props.currentSongIndex].src).then(cacanKsjbdsjd => {
      tdusfsfsdfTud.current(cacanKsjbdsjd);
    });
  }
  }, [props.currentSongIndex, props.songs, tdusfsfsdfTud]);

const rrdrrdqwwqftfSDRihwe = event => {
if (mmnnSfh.current != null) {
  if (!laslkallkllasTgi.current) {
      laslkallkllasTgi.current = zzmnmnzxmnzmxnzQde.current.createMediaElementSource(mmnnSfh.current);
      laslkallkllasTgi.current.connect(laslkallkllasQde.current);
      laslkallkllasQde.current.connect(zzmnmnzxmnzmxnzQde.current.destination);
  }
  var ljhkltyjhUlklj = mmnnSfh.current.play();
  if (ljhkltyjhUlklj !== undefined) {
    ljhkltyjhUlklj.then(_ => {
      mmnnSfh.current.play();
    })
    .catch(error => {
      if (mcnVmcn) {
        if(mmnnSfh != null && mmnnSfh.current != null) {
          mmnnSfh.current.pause();
        }
        if(ksjhkgakETUUIUI != null && ksjhkgakETUUIUI.current != null) {
          ksjhkgakETUUIUI.current.pause();
        }
      }
    });
  }
} else {
  if(mcnVmcn && mmnnSfh.current != null) {
    mmnnSfh.current.pause();
  }
}
};
 
async function mppqbcwewrxexWknjan(knkWWEFW) {
  const qpowqpqpwoqwpo = await fetch(knkWWEFW);
  const dsdqwqsqji = await qpowqpqpwoqwpo.blob(); 
  const cacanKsjbdsjd = URL.createObjectURL(dsdqwqsqji);
  return cacanKsjbdsjd;
}
  useEffect(() => {
    if (ksjhkgakETUUIUI != null && ksjhkgakETUUIUI.current != null && mcnVmcn) {
      ksjhkgakETUUIUI.current.play().catch((error) => {        
      });
      ksjhkgakETUUIUI.current.pause();
      mmnnSfh.current.pause();
    } else if(ksjhkgakETUUIUI && mcnVmcn) {       
        //do nothing
    }    
  }, [mcnVmcn]);

    useEffect(() => {
      var ewskddjlkas =  mmnnSfh.current;
        if (mcnVmcn && mmnnSfh.current != null ) { 
          mmnnSfh.current.play();
        } 
      return () => {
        if(ewskddjlkas && isSkipSong && mmnnSfh.current != null) {
          ewskddjlkas.pause();
        }
      };
    });

    useEffect(() => {
      var ewskddjlkas =  ksjhkgakETUUIUI.current;
        if (mcnVmcn && ksjhkgakETUUIUI.current != null) { 
            ksjhkgakETUUIUI.current.play();
        } 
      return () => {
        if(ewskddjlkas && ksjhkgakETUUIUI.current != null) {
            ewskddjlkas.pause();
        }
      };
    });
  
    useEffect(() => {
      yuyhgdhgfh(true); 
    }, []);

    const handleSongEnd = () => {
      if(mcnVmcn) {
        yuyhgdhgfh(!mcnVmcn);
        mmnnSfh.current.pause();
      }
      isSkipSong = true;
      Zsesrftgolkjuyut();
      yuyhgdhgfh(mcnVmcn);
    };

    useEffect(() => {
      mmnnSfh.current.addEventListener("ended", handleSongEnd);
      swdPnkeij(3000);
          setTimeout(() => {
            yuyhgdhgfh(true);
          }, oiuytyui);
      return () => {
        mmnnSfh.current.removeEventListener("ended", handleSongEnd);
      };
    }, [handleSongEnd, props.currentSongIndex]);
   
    const Zsesrftgolkjuyut = (forwards = true) => {
      props.setCurrentSongIndex((prevIndex) => {
        let newIndex = prevIndex;
        if(mcnVmcn) {
          yuyhgdhgfh(false);
        }
        if (forwards) {
          newIndex = (newIndex + 1) % props.songs.length;
          swdPnkeij(3000);
          setTimeout(() => {
            yuyhgdhgfh(true);
          }, oiuytyui);
        } else {
          newIndex = (newIndex - 1 + props.songs.length) % props.songs.length;
          swdPnkeij(3000); 
          setTimeout(() => {
            yuyhgdhgfh(true);
          }, oiuytyui);
        }
        return newIndex;
      });
      isSkipSong = false;
    };

  const handlePlayRandomMusic = () => {
    if(mcnVmcn) {
      yuyhgdhgfh(false);
    }
    const randomIndex = Math.floor(Math.random() * props.songs.length);
    props.setCurrentSongIndex(randomIndex);   
    swdPnkeij(3000);
    setTimeout(() => {
      yuyhgdhgfh(true); 
    }, oiuytyui);
    
  };

  const kpkmpmkxdrdrxdxct = () => { 
    if(mmnnSfh != null && mmnnSfh.current != null) {
      mmnnSfh.current.currentTime = 0; 
    }
  };
  
  const komomxqwdqoijqwd = {
    backgroundColor: '#1DB954',
    iwyeqieyqwyeqouyuyweww: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  };
  
  const kokkookkuj = () => {
    window.location.href = 'https://open.spotify.com/playlist/6jpofjjyyoMuCTdiWpl3ww?si=ade38eb0013c4fae';
  };
// eslint-disable-next-line no-lone-blocks
{/* //this is my Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, mostly interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions. 
//overall check https://linktr.ee/Muthukumaran.Azhagesan */}
  return (
      <div className="w-full whitespace-wrap flex-center justify-center p-3 fade-in transition-transform transform-gpu dark:bg-gray-700 dark:text-white ui-widget-content">
        <div><br/>
          <div className="nextsong-details whitespace-wrap justify-center border-collapse ui-widget-content">
            <img
              // src={props.songs[props.nextSongIndex].img_src}
              src={`${process.env.PUBLIC_URL}/songs_images/${props.songs[props.nextSongIndex].img_src}`}
              alt={props.songs[props.nextSongIndex].title}
              style={{ width: "4em", height: "auto" }}/>
            <div className="animate-blink flex flex-wrap whitespace-break-spaces dark:text-white">
              Upcoming Song:&nbsp;
              {props.songs[props.nextSongIndex].title} &nbsp; by &nbsp;
              {props.songs[props.nextSongIndex].artist}&nbsp; from album &nbsp;&nbsp;
              {props.songs[props.nextSongIndex].album}
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <button style={komomxqwdqoijqwd} onClick={kokkookkuj}>
          Click, search, and buy this & more favorite music on Spotify
        </button>
        <div className="w-11/12 music-player text-light text-light-shadow-lg font-thin dark:bg-gray-700 dark:text-white flex-center justify-center ui-widget-content">
        <audio
            src={`${process.env.PUBLIC_URL}/songs/${props.songs[props.currentSongIndex].src}`} 
            ref={mmnnSfh} controls style={{ display: 'none' }}            
            preload="auto"
            onEnded={handleSongEnd}
            onPlay={rrdrrdqwwqftfSDRihwe}></audio>
        <PlayerDetails
            className="animate-fade-in to-blue-800  dark:bg-gray-700 dark:text-white"
            song={props.songs[props.currentSongIndex]}/>
        <PlayerControls
            isPlaying={mcnVmcn}
            setIsPlaying={yuyhgdhgfh}
            SkipSong={Zsesrftgolkjuyut}/>
        <div className="flex left relative rounded-lg p-1 items-center justify-center ui-widget-content">      
        <div className="special-div canvas-container flex-direction: row">  
        <div><canvas id="canvas1" ref={nbzxcnzbSfh}  className="h-auto p-1 shadow-inner relative "  style={{ width: '175px', height: '49px', backgroundColor: 'transparent', zIndex: 1000}}>                        
          </canvas>
          <canvas id="canvas2" ref={nbzxcnzbSfh2} className="h-auto p-1 shadow-inner relative "  style={{ width: '175px', height: '49px', backgroundColor: 'transparent', zIndex: 1000}}>         
            {/* <img src={import.meta.url + {equalizerBgImage}} alt="Music_Equalizer_Grid" /> */}
          </canvas></div>
          <br/>
          <div>
            <div className="p-1.5"></div>
            {props.songs[props.currentSongIndex].display_banner ? (
            <SpectrumAnalyzer text="Visit Kumar's autoshields website {click AUTO SHIELDS link shown above to go there} to learn about his 3 useful inventions, of which 2 are life saving! All are patented and ready to go!" /> ) : (
              <div className="plain-container flex-auto">
                <p/>
              </div> 
            )}
            </div>
         </div>                             
      </div>
      <br/>
      <div className="text-xs">Digital LED Music Visualizer by Mr.Muthukumaran Azhagesan(Kumar)</div> 
      <div className="parent-div flex-direction: row">          
      {options.map((option) => (
        <div className="w-11/12 h-1 p-1 rounded-xl shadow-xl text-black flex items-center text-sm"
          key={option.text}
          onClick={() => qwerTsdfg(option) }
          style={{ backgroundColor: option.color, padding: '10px', margin: '5px' }}
        >
          {option.text}
        </div>
      ))}      
      </div>
          <div className="player__footer dark:bg-gray-700 dark:text-white">
            <ul className="list list--footer">
              <li>
                <a
                  id="likesCounter"
                  href="#"
                  className="list__link"
                  onClick={() => {
                    setLikes((prevLikes) => {
                      const updatedLikes = [...prevLikes];
                      updatedLikes[props.currentSongIndex]++;
                      return updatedLikes;
                    });
                  }}>
                  <i className="fa fa-heart-o"></i>
                  Likes: {likes[props.currentSongIndex]}
                </a>
              </li>
              <li>
                <a id="playRandomMusic"
                  href="#"
                  className="list__link"
                  onClick={handlePlayRandomMusic}>
                  <i className="fa fa-random"></i>
                </a>
              </li>
{/* //this is my Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, mostly interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions. */}

              <li>
                <a
                  id="playThisAgain"
                  href="#"
                  className="list__link"
                  onClick={kpkmpmkxdrdrxdxct}>
                  <i className="fa fa-undo"></i>
                </a>
              </li>

              <li>
                <a href="#" className="list__link">
                  <i className="fa fa-ellipsis-h"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
  
}
export default Sdvjsdfjkasdhjhjh;
//this is my Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, mostly interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions.