import React, { useState, useEffect} from "react";

import Loading from './Loading_IBMWatsonHackathon2024'; // Path to your Loading component


// function MyButton1() {
//   return (
//     <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//       Go
//     </button>
//   );
// }

function IBMWatsonXHackathon2024() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => setIsLoading(false), 2000); 
  }, []);

  // return isLoading ? <Loading /> : <div>Content is loaded</div>; 
  return isLoading ? <Loading /> : <Loading />; 
}
export default IBMWatsonXHackathon2024;