import React, { useState } from "react";
import JavaLogo from "./images/java-tech-stack/Java-Logo.png";
import SAPLogo from "./images/java-tech-stack/SAP-Logo.png";
import AppStore from "./images/ios-app-store.png";
import softwares from "./images/java-tech-stack/My-All-in-one-latest-software-experience.png";


export default function SoftwareCareerExpertise() {
  const [collapse, setCollapse] = useState(true);

  const handleClick = () => {
    setCollapse(!collapse);
  };
  
  function isMobileDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = [
      "android",
      "webos",
      "iphone",
      "ipad",
      "ipod",
      "blackberry",
      "windows phone",
    ];
    return mobileKeywords.some((keyword) => userAgent.includes(keyword));
  }

 // Usage
 if (isMobileDevice()) {
  return ( 
    <div className="mobile-container w-10/12 bg-white  dark:bg-gray-600 dark:text-white overflow-x-auto rounded-lg shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 mx-3 text-wrap text-pretty max-w-fit  ui-widget-content">         
      <div>
        <br />
        <br />
        <br />
      </div>
      <div className="mx-auto p-3 flex flex-wrap w-5/6 cursor-pointer hover:bg-yellow-500 dark:hover:bg-slate-700 font-weight:font-extrabold text-justify ui-widget-content" onClick={handleClick}>Click here to expand and then <i>scroll down a bit</i>: to view Mr. Muthukumaran Azhagesan's software expertise</div>      
      <div className="container  dark:bg-gray-600 dark:text-white p-4 mx=auto  ui-widget-content">
      <img className="p-1 h-auto w-auto object-contain music-player space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={softwares} alt="Software skillset" />
      <div className={`div2 dark:bg-gray-600 dark:text-white  ui-widget-content ${collapse ? 'collapsed' : ''}`}>                             
      <div className="h-3 w-auto  ui-widget-content">&nbsp;</div>
      <div className="text-justify  ui-widget-content">
      <p className="w-1/2 h-auto p-3 raised-content flex flex-wrap dark:bg-gray-900  shadow-xl rounded mx-auto overflow-x-auto sticky cursor-pointer  hover:bg-yellow-500 dark:hover:bg-slate-700 text-black min-w-fit whitespace-wrap font-bold ui-widget-content">
          For Java, Spring boot, microservices/cloud, UI/UX(web/full stack), <br/>visit:&nbsp;&nbsp;&nbsp; 
          <a href="https://www.damnittameitai.co/JavaAndWebStack" className="hover:bg-green-500 dark:hover:bg-slate-700">https://www.damnittameitai.co/JavaAndWebStack
          <img
            className="p-1 w-45 h-auto object-contain   shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={JavaLogo}
            alt="JavaLogo"
          />
          </a>
      </p>{" "}             
      <p className="p-3 raised-content flex flex-wrap dark:bg-gray-900  shadow-xl rounded  mx-auto overflow-x-auto sticky cursor-pointer  hover:bg-yellow-500 dark:hover:bg-slate-700  text-black w-fit whitespace-wrap  font-bold  ui-widget-content">
          For SAP consulting experience, visit:&nbsp;&nbsp;&nbsp;
          <a href="https://www.damnittameitai.co/RealSAPConsultingExperience" className="hover:bg-green-500 dark:hover:bg-slate-700">https://www.damnittameitai.co/RealSAPConsultingExperience</a>
      </p>{" "}             
      <p className="p-3 raised-content flex flex-wrap dark:bg-gray-900  shadow-xl rounded   mx-auto overflow-x-auto sticky cursor-pointer  hover:bg-yellow-500 dark:hover:bg-slate-700 text-black w-fit whitespace-wrap  font-bold  ui-widget-content">          
          iOS/Swift:&nbsp;&nbsp;&nbsp;
          Muthukumaran Azhagesan's own iOS Weather App is on Apple AppStore:<br/>
          search for Kumar's app named Parents’ Weather Companion on Appstore, purchase it, install and use (available in 175 countries worldwide)
      </p>{" "}        
      <p className="p-3 raised-content flex flex-wrap dark:bg-gray-900    mx-auto shadow-xl rounded overflow-x-auto sticky cursor-pointer hover:bg-yellow-500 dark:hover:bg-slate-700 text-black w-fit whitespace-wrap  font-bold  ui-widget-content">
          Visit Kumar's linkedin profile which has more than a handful of professional recommendations:&nbsp;&nbsp;&nbsp; 
          <a href="https://www.LinkedIn.com/in/mazhagesan" className="hover:bg-green-500 dark:hover:bg-slate-700">https://www.LinkedIn.com/in/mazhagesan</a>
      </p>{" "}
      <br/>        
      </div>
    </div>
    </div>
    </div> 
  ); 
} else {
  return ( 
    <div className=" bg-white dark:bg-gray-600 dark:text-white overflow-x-auto items-center sticky rounded-lg overflow-hidden shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 p-1 mx-auto ui-widget-content">         
      <div>
        <br />
        <br />
        <br />
      </div>
      <div className="ui-widget-content cursor-pointer  hover:bg-yellow-500 dark:hover:bg-slate-700 font-weight: font-extrabold" onClick={handleClick}>Click here to expand and then <i>scroll below</i> to view: Mr. Muthukumaran Azhagesan's software expertise</div>      
      <div className="container dark:bg-gray-600 dark:text-white p-4 mx=auto  ui-widget-content">
      <div className={`div2 dark:bg-gray-600 dark:text-white  ui-widget-content ${collapse ? 'collapsed' : ''}`}>
      <div className="h-1 w-auto">&nbsp;</div>
      <div className="text-justify object-contain object-center ui-widget-content">
      <p className="p-3 flex justify-content-center align-items-center raised-content dark:bg-gray-900 shadow-xl rounded m-24 overflow-x-auto sticky cursor-pointer hover:bg-yellow-500 dark:hover:bg-slate-700 text-black w-fit whitespace-wrap font-bold  ui-widget-content">
      <p className="justify-content-center align-items-center">
        For Java, Spring boot, microservices/cloud, UI/UX, full stack experience, visit: 
        <a href="https://www.damnittameitai.co/JavaAndWebStack" className="hover:bg-green-500 dark:hover:bg-slate-700">
          <img
            className="w-1/2 h-1/2 md:rounded-none mx-auto object-contain object-center shadow-xl hover:scale-125"
            src={JavaLogo}
            alt="JavaLogo"/>
        </a>
        </p>
      </p>       
      <br/>
      <div className="text-justify object-contain object-center ui-widget-content">
      <p className="p-3 flex justify-content-center align-items-center raised-content dark:bg-gray-900 shadow-xl rounded m-24 overflow-x-auto sticky cursor-pointer hover:bg-yellow-500 dark:hover:bg-slate-700 text-black w-fit whitespace-wrap font-bold  ui-widget-content">
        <p className="justify-content-center align-items-center">
        For SAP consulting experience, visit: 
        <a href="https://www.damnittameitai.co/RealSAPConsultingExperience" className="hover:bg-green-500 dark:hover:bg-slate-700">
          <img
            className="w-1/2 h-1/2 md:rounded-none mx-auto object-contain object-center shadow-xl hover:scale-125"
            src={SAPLogo}
            alt="SAPLogo"/>
        </a>
        </p>
      </p>    
      </div>       
        <p className="p-1 raised-content flex flex-wrap items-center object-contain object-center dark:bg-gray-900  shadow-xl rounded  m-24 overflow-x-auto sticky cursor-pointer  hover:bg-yellow-500 dark:hover:bg-slate-700  text-black w-fit whitespace-wrap font-bold  ui-widget-content">          
          iOS/Swift:&nbsp;&nbsp;&nbsp;
          Muthukumaran Azhagesan's own iOS Weather App is on Apple AppStore:<br/>
          search for Kumar's app named Parents’ Weather Companion on Appstore, purchase it, install and use (available in 175 countries worldwide)
          <a href="https://apps.apple.com/us/app/parental-weather-companion/id1575019437">
            <img
      className="w-auto h-auto md:rounded-none mx-auto object-contain object-center shadow-xl hover:scale-125" 
      src={AppStore}
      alt="iOS AppStore download link"
      />
    </a>
        </p>{" "}        
        <p className="p-3 raised-content flex flex-wrap dark:bg-gray-900  shadow-xl rounded overflow-x-auto sticky cursor-pointer hover:bg-yellow-500 dark:hover:bg-slate-700 text-black w-fit whitespace-wrap font-bold  ui-widget-content">
          Visit Kumar's linkedin profile which has more than a handful of professional recommendations:&nbsp;&nbsp;&nbsp; 
          <a href="https://www.LinkedIn.com/in/mazhagesan" className="hover:bg-green-500 dark:hover:bg-slate-700">https://www.LinkedIn.com/in/mazhagesan</a>
        </p>{" "}
        <br/>        
        </div>
    </div>
    </div>
    </div> 
  );
};
}