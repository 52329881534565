import React, { useState } from "react";
import Voice2EntertainmentImage from "./Voice-to-entertainment";

function MyButton1() {
  return (
    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Go
    </button>
  );
}

export default function Page1Hello() {
  return (
    <section className="bg-gray-100 py-8  ui-widget-content">
      <div className="container mx-auto px-2 dark:bg-gray-900 ">        
        <h2 className="text-3xl font-bold mb-6">Entertainment via voice commands</h2>
        <Voice2EntertainmentImage />
      </div>
    </section>
  );
}