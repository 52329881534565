import React, { useEffect, useRef } from 'react';
import '../css/spectrum-analyzer.css';

const SpectrumAnalyzer = ({ text }) => {
  const needleRef = useRef(null);

  useEffect(() => {
    const updateNeedle = () => {
      if (needleRef.current) {
        const frequency = Math.random() * 100; // Simulate frequency data
        needleRef.current.style.transform = `rotate(${frequency - 50}deg)`;
      }
    };

    const interval = setInterval(updateNeedle, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="spectrum-analyzer">
      <div className="analyzer-display">
        {/* <div className="needle" ref={needleRef}></div> */}            
        <div className="text-white font-bold w-auto flex justify-center items-center">   
        <a href="https://www.autoshields.website" className="text-white font-bold w-auto flex justify-center items-center pt-4"><u>AUTO SHIELDS</u></a></div>               
        </div>
        <div className="font-thin w-auto flex justify-center items-center scrolling-text">{text}</div>
    </div>
  );
};

export default SpectrumAnalyzer;	