import React, { useState, useEffect, useRef } from 'react';

function TypingAnimation({ text, speed = 75 }) {
  const [currentText, setCurrentText] = useState('');
  const textRef = useRef(null);

  useEffect(() => {
    const writer = async () => {
      for (let i = 0; i < text.length; i++) {
        await new Promise(resolve => setTimeout(resolve, speed));
        setCurrentText(text.substring(0, i + 1));
      }
    };

    writer();
  }, [text, speed]);  // Dependency array to trigger on text or speed change

  return (
    <span ref={textRef}>{currentText}</span>
  );
}
export default TypingAnimation;