import React from 'react';
import Atropos from 'atropos/react';
import 'atropos/css';

const AtroposImage = ({ imageUrl, altText, classNames }) => {
  const atroposOptions = {
    scale: 1.2, // Adjusts scaling on hover (default: 1.2)
    depth: 300,  // Depth of the 3D effect (default: 100)
    angle: 0,    // Rotation angle on hover (default: 0)
    // Other options as needed (refer to Atropos documentation)
  };
Atropos(atroposOptions);

  return (
    <Atropos
      activeOffset={40}
      shadow={true} // Enable shadow
      shadowScale={1} // Adjust shadow scale
      shadowOffset={5} // Adjust shadow offset
      // shadowBlur={1} // Adjust shadow blur
      highlight={true}
      onEnter={() => console.log('Enter')}
      onLeave={() => console.log('Leave')}
      onRotate={(x, y) => console.log('Rotate', x, y)}
    >
      <img src={imageUrl} alt={altText} className={classNames}/>
    </Atropos>
  );
  
};

export default AtroposImage;