import React from "react";
import IBMWatsonAIHackathon2024Marketing from "./IBMWatsonAIHackathon2024MarketingContent";

export default function IBMwatsonxai2024Page() {
  return (
    <section className="bg-gray-100 py-8 ui-widget-content">
      <div className="container mx-auto px-2">        
        <IBMWatsonAIHackathon2024Marketing />
      </div>
    </section>
  );
}