import React, { useState } from "react";
import youTube_Navigator from './images/YouTube_Navigator.png';

export default function YouTubeNavigator() {
  const [collapse, setCollapse] = useState(true);
  const [expandedDivId, setExpandedDivId] = useState(null);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const handleDivClick = (divId) => {
    setExpandedDivId(divId === expandedDivId ? null : divId);
  };
  return (
    <div className="flex w-auto h-auto items-center justify-center mx-0.5 bg-white dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 ui-widget-content" onClick={handleClick}>            
    <div className="justify-items-start ui-widget-content">        
          <img 
            className="w-80 h-auto"
            src={youTube_Navigator}
            alt="YouTube Navigator"
          />
      </div>
      <div className="flex w-auto h-auto items-center justify-center mx-0.5 bg-white  dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50  ui-widget-content" onClick={handleClick}><b className="text-left">YouTube Navigator</b></div>
      <div className="dark:bg-gray-600 dark:text-white">
      <div className={`dark:bg-gray-600 dark:text-white border-none w-${expandedDivId === 'div1' ? 'full' : '3/4'}  ${collapse ? 'collapsed' : ''}`}>        
      <div className="h-7 w-auto">&nbsp;</div>
      <div className="text-justify p-4 text-xs">
        <br />
        <p>
          <b>Objective:</b>&nbsp;&nbsp;&nbsp;Provide summaries in the style the user needs, as per
          their prompt, save time watching videos.{" "}
        </p>
        <br />
        <p>
          <b>About the project:</b>&nbsp;&nbsp;&nbsp;A cool project that our global team worked
          on, and released during the time ChatGPT kept us in awe a few months
          back in 2023. This is a chrome plugin core idea by Demian Netliukh.
          It's a great team effort. Got appreciation from lablab.ai founding
          team
        </p>
        <br />
        <p>
          <b>Functionalities:&nbsp;&nbsp;&nbsp;</b> User installs our chrome plugin extension, and logs in with their google.com account same as their youtube account. And enters their desired prompt of the detail that they need from the Youtube video. Within 
          seconds, they are provided with the summary tailored to their prompt. One of the first apps/extensions demonstrated using chatGPT!{" "}
        </p>
        <br />
        <b>Demo:&nbsp;&nbsp;&nbsp;</b> can be seen by <a href="https://www.google.com/url?q=https%3A%2F%2Flablab.ai%2Fevent%2Fai-startup-hackathon%2Fyoutube-navigator%2Fyoutube-navigator&sa=D&sntz=1&usg=AOvVaw3iyFJWCmuCQbQBKlwYrXvEhttps://sites.google.com/view/autoshieldsllc/a-i-machine-learning?authuser=0">clicking here</a>.
      </div>
    </div>
    </div>
    </div>
  );
}