import {React, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faForward,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";

function PlayerControls(props) {
    // State to manage tooltip visibility
    const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="music-player--controls bg-white  dark:bg-gray-700 dark:text-white;  ui-widget-content">
      <button className="skip-btn" onClick={() => props.SkipSong(false)}
      onMouseEnter={() => setShowTooltip(true)}   // Show tooltip on mouse enter
      onMouseLeave={() => setShowTooltip(false)}  // Hide tooltip on mouse leave
      >
        <FontAwesomeIcon icon={faBackward} />
      </button>
      <button
        className="play-btn"
        onClick={() => props.setIsPlaying(!props.isPlaying)}>
        <FontAwesomeIcon icon={props.isPlaying ? faPause : faPlay}/>
      </button>
      <button className="skip-btn" onClick={() => props.SkipSong()} 
        onMouseEnter={() => setShowTooltip(true)}   // Show tooltip on mouse enter
        onMouseLeave={() => setShowTooltip(false)}  // Hide tooltip on mouse leave
      >
        <FontAwesomeIcon icon={faForward} />
      </button>
       {/* Tooltip */}
       {showTooltip && <div className="tooltip-container">For best result, stop current song, then click on (this)skip button</div>}
    </div>
  );
}

export default PlayerControls;