import React from 'react';
import WeatherWarriors from "./images/weather_warriors.gif";
import WeatherWarriorsPresentation from "./files/Weather-Warriors-Falcon-Hackathon-July-to-August-2024.pdf";

function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <p>Loading, please wait...</p> 
      <p>Falcon AI71 Hackathon July to August 2024</p>
      <p>Muthukumaran Azhagesan</p>
      <div className="justify-items-start ui-widget-content">        
      {/* <a href="/files/Weather-Warriors-Falcon-Hackathon-July-to-August-2024.pdf"><img 
            className="w-96 h-auto object-contain"
            src={WeatherWarriors}
            alt="Weather<br/>Warriors"
          /></a> */}

        <img
          src={WeatherWarriors} className="w-96 h-auto object-contain"
          alt="Falcon AI71 Hackathon July to August 2024. Muthukumaran Azhagesan"
          onClick={() => {
            const link = document.createElement('a');
            // link.href = '/files/'; // replace with your PDF file path
            link.download = {WeatherWarriorsPresentation}; // replace with your PDF file name
            link.click();
          }}
        />
      </div>
     
    </div>
  );
}

export default Loading;