import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import PlayerDetails from "./PlayerDetails";
import PlayerControls from "./PlayerControls";
import "../css/index.css"

function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

function PlayerForMyResumes(props) {
  const audioElement = useRef(null);
  const [isPlaying, setIsPlaying, isTracking] = useState(false);
  const handleTrackingChange = () => {
    isTracking = !isTracking;
  };

  useEffect(() => {
    if (isPlaying) {
      audioElement.current.play();
    } else {
      audioElement.current.pause();
    }
  });

  useEffect(() => {
    setIsPlaying(true); // Autoplay when component is loaded
  }, []);

  useEffect(() => {
    audioElement.current.addEventListener("ended", handleSongEnd);
    return () => {
      audioElement.current.removeEventListener("ended", handleSongEnd);
    };
  }, [props.currentSongIndex]);

  const handleSongEnd = () => {
    SkipSong();
  };

  const SkipSong = (forwards = true) => {
    props.setCurrentSongIndex((prevIndex) => {
      let newIndex = prevIndex;

      if (forwards) {
        newIndex = (newIndex + 1) % props.songs.length;
      } else {
        newIndex = (newIndex - 1 + props.songs.length) % props.songs.length;
      }
      return newIndex;
    });
  };

  return (
    <>
      <div className="w-full whitespace-wrap flex-center justify-center p-3 bg-white  dark:bg-gray-700 dark:text-white;">
        <div>          
          <br></br>         
            <div className="rounded-lg font-light bg-opacity-50 align-baseline h-1 p-3 bg-white  dark:bg-gray-700 dark:text-white;">              
              <b>{props.songs[props.nextSongIndex].title} </b>&nbsp; by &nbsp;
              <b>{props.songs[props.nextSongIndex].artist}</b>           
              &nbsp; &nbsp;from album&nbsp; &nbsp;
              <b>{props.songs[props.nextSongIndex].album}</b>
            </div>
        </div>
        <div className="music-player bg-white  dark:bg-gray-700 dark:text-white;">
          <audio
            src={props.songs[props.currentSongIndex].src}
            ref={audioElement}
          ></audio>
          {/* <PlayerDetails
            className="animate-fade-in to-blue-800"
            song={props.songs[props.currentSongIndex]}
          /> */}
          <PlayerControls
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            SkipSong={SkipSong}
          />
<div>&nbsp;</div>          
        </div>       
          {isTracking && (<ReactGA trackingId="G-7Q9P6SPPYM" />)}  
      </div>
    </>
  );
}
export default PlayerForMyResumes;
