import {React} from "react";
import YouTubeNavigator from "./YouTube-Navigator";

// function MyButton1() {
//   return (
//     <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//       Go
//     </button>
//   );
// }

export default function Page2() {
  return (
    <section className="bg-gray-100 py-8">
      <div className="container mx-auto px-2">               
        <YouTubeNavigator />
      </div>
    </section>
  );
}