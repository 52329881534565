import React from "react";
import AtroposImage from "./AtroposImage.js";

function PlayerDetails(props) {
  return (
    <div className="music-player--details bg-white  dark:bg-gray-700 dark:text-white;  ui-widget-content atropos">
      <div className="details-img flex justify-center items-center">
        {/* <img
          className="details-img--image items-center"
          src={props.song.img_src}
          alt={props.song.title}       
        /> */}
        {/* <div className="atropos">
          <div className="atropos-scale">
            <div className="atropos-rotate">
              <div className="atropos-inner"> */}
        {/* <img
                  className="details-img--image items-center"
                  src={`${process.env.PUBLIC_URL}/songs_images/${props.song.img_src}`}
                  alt={props.song.title}
                /> */}
        {/* <div class="atropos-frame"> */}
          <AtroposImage
            imageUrl={`${process.env.PUBLIC_URL}/songs_images/${props.song.img_src}`}
            altText={props.song.title}
            classNames="details-img--image"
          />
        {/* </div> */}
        
      </div>
      <div className="range"></div>
      <div className="artist-info w-full whitespace-wrap p-1">
        <h3 className="details-title">{props.song.title}</h3>
        <h4 className="details-artist">{props.song.artist}</h4>
        <div className="line"></div>
      </div>
    </div>
  );
}

export default PlayerDetails;
