import React, { useState, useEffect } from 'react';

const RandomClassnameDiv = ({ children }) => { 
  

  const [randomClass, setRandomClass] = useState('');

  useEffect(() => {
    const classNames = ['aqua1-url', 'aqua2-url', 'aqua3-url', 'bluegreen1-url', 'brightgreen1-url', 'cylinderaqua1-url', 'cylinderblue1-url', 'cylinderblue2-url', 'cylinderbluegreenmetal-url', 'cylinderdarkmetal-url', 'cylindergolden-url', 'cylindergreen1-url', 'cylindergreen2-url', 'cylindergreymetal-url', 'cylindershinyblue1-url', 'cylinderyellow1-url', 'darkgrey1-url', 'darkpink1-url', 'flataqua-url', 'flatblue-url', 'flatbrightgreen-url', 'flatbrightgreen2-url', 'flatbrightred-url', 'flatdarkblue-url', 'flatlightbeige-url', 'flatlightgreen1-url', 'flatpink1-url', 'flatred1-url', 'flatsaffron1-url', 'flatsaffron2-url', 'flatyellowblue-url', 'gold1-url', 'green1-url', 'green2-url', 'green3-url', 'green4-url', 'lightblue1-url', 'liquidgreen1-url', 'metalgrey1-url', 'shinygreen1-url', 'violet1', 'red-glass-bg'];
    const getRandomClassname = () => {
        const randomIndex = Math.floor(Math.random() * classNames.length);
        return classNames[randomIndex];
      };
    setRandomClass(getRandomClassname());
  }, []);

  return (
    <div className={randomClass}>
      {children}
    </div>
  );
};

export default RandomClassnameDiv;