import React from 'react';
import AW_Wingman from "./images/Advanced-Weather-Wingman-Coverpage.png";
import IBMWatsonAIHackathon2024Presentation from "./files/AW-Wingman-IBM-Watsonx-Advanced-Weather-Wingman-Team---My-Pitch-Deck-latest.pdf";


function LoadingIBMWatsonAI() {
  // const VIDEO_ID = 'Cmug1ib7fSQ';

  return (
    <div className="loading-container">
      {/* <div className="loading-spinner"></div> */}
      {/* <p>Loading, please wait...</p>  */}
      <p>IBM watsonx AI Hackathon August 2024</p>
      <p>Muthukumaran Azhagesan</p>
      <div className="justify-items-start ui-widget-content">        
        <img
          src={AW_Wingman} className="w-96 h-auto object-contain"
          alt="IBM watsonx ai Hackathon August 2024. Muthukumaran Azhagesan"
          onClick={() => {
            const link = document.createElement('a');
            // link.href = '/files/'; // replace with your PDF file path
            link.download = {IBMWatsonAIHackathon2024Presentation}; // replace with your PDF file name
            link.click();
          }}
        />
        <br></br>
        <a
        href="https://youtu.be/Cmug1ib7fSQ"
        target="_blank"
        rel="noopener noreferrer"
      >
         <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#0000FF',
        }}>Watch on YouTube</div>
      </a>
      </div>     
    </div>
  );
}
export default LoadingIBMWatsonAI;