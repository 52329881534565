import React, { useState } from "react";
import AW_Wingman from "./images/Advanced-Weather-Wingman-Coverpage.png";
import IBMWatsonAIHackathon2024Presentation from "./files/AW-Wingman-IBM-Watsonx-Advanced-Weather-Wingman-Team---My-Pitch-Deck-latest.pdf";
// import YouTube from 'react-youtube';

export default function IBMWatsonAIHackathon2024Marketing() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="flex w-auto h-auto items-center bg-white dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 ui-widget-content"
      onClick={handleClick}>
      <div  className="justify-items-start ui-widget-content"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto w-auto object-contain"
            src={AW_Wingman}
            alt="Advanced Weather Wingman"
          /> )}
      </div>
      <div
        className="flex w-auto h-auto items-center mx-0.5 bg-white  dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50  ui-widget-content"
        onClick={handleClick}>
        <b className="text-left">
          AW <br/> Wingman
        </b>
      </div>
      <div className="dark:bg-gray-600 dark:text-white">
        <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >
          <div className="h-7 w-auto">&nbsp;</div>
          <div className="text-justify p-4 text-xs">
            <br />
            <p>
              <b>Objective:&nbsp;&nbsp;&nbsp;</b>Keep global travel seamless to
              air travelers regardless of extreme weather conditions.{" "}
            </p>
            <br/>
            <p>
              <b>Presentation 1(My pitch of my app idea of Advanced Weather Wingman) on lablab.ai:</b>
              <a href="https://lablab.ai/event/ibm-watsonx-challenge/advanced-weather-wingman">My pitch of my app idea of Advanced Weather Wingman</a>
            </p>{" "}
            <p>
              <b>Presentation 2(Demo of interaction with IBM watsonx.ai) on YouTube:</b>
              <a href="https://www.youtube.com/watch?v=Cmug1ib7fSQ">Demo of interaction with IBM watsonx.ai</a>
            </p>{" "}
            <br />
            <p>
              <b>About the project:&nbsp;&nbsp;&nbsp;</b>Keeping global travel
              seamless to air travelers regardless of extreme weather
              conditions. Relaxed passengers, stress free airlines airport
              agents, and hotel agents. Passengers travel hassle-free even in
              bad winter weather conditions No head-ache for Airlines and
              Airline agents Overall smooth operating airport Happier people,
              happier countries, happier/friendlier world Leveraging IBM watsonx
              AI, Machine learning, Deep learning, especially IBM's Granite
              models(chat). There are many advantages in using IBM's watsonx ai
              because it is not just many sets of LLMs, but IBM provides options
              to deploy the Machine learning models we generate on their IBM
              Cloud, allows integration of data from external sources for answering queries
              with accurate data, and much more.
            </p>{" "}
            <br />
            <p>
              <b>Concept, Programming, and Integration:&nbsp;&nbsp;&nbsp;</b>{" "}
              Muthukumaran Azhagesan, Kumar.ALGate@gmail.com and his team members: Adan Malik, Ahmad Raza, and Aun Raza. 
              (Further detail along with their photos are on the AW Wingman page and presentation videos. Links are listed above.)
            </p>{" "}
            <br />
            <p>
              <b>Thanks:&nbsp;&nbsp;&nbsp;</b>to mentor/head judge Lablab.ai Ms. Walaa Nasr, IBM Mentor: Gaurav Ramakrishna
            </p>{" "}
            <br />
            <p>              
            </p>
          </div>
        </div>
      </div>
    </div>
  );  
}