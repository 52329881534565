import React, { useState } from "react";
import LotteryPrediction from "./Lottery-Prediction";

function MyButton1() {
  return (
    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Go
    </button>
  );
}

export default function Page3() {
  return (
    <section className="bg-gray-100 py-8 ui-widget-content">
      <div className="container mx-auto px-2">        
        <LotteryPrediction />
      </div>
    </section>
  );
}